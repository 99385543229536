.Footer {
  background-color: var(--footerBackgroundColor);
  height: auto;
  position: relative;
  width: 100%;
}

.ImageEurope {
  margin-top: 1rem;
  width: 100%;
  max-height: 150px;
}

.FooterBody {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ImageDiv {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.versionTag {
  position: absolute;
  color: var(--textColor);
  right: 0;
  bottom: 0;
  padding-right: 10px;
  padding-bottom: 10px;
  font-size: 10px;
}

.footerLink {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  color: var(--textColor);
}

.footerLink a {
  color: var(--textColor) !important;
}

.footerLink > div {
  padding: 1% 10px 10px 10px;
}

@media only screen and (max-width: 548px) {
  .FooterBody {
    display: grid;
  }
  .Footer {
    min-height: 150px;
  }
  .footerLink {
    flex-direction: column;
  }
}
.ImageButton {
  background-color: var(--footerBackgroundColor);
  border: none;
}

