.publishReject{
    background-color: white;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.logoApp {
    position: absolute;
    left: 5rem;
    top: 2rem;
    text-align: left;
}
    .logoApp img {
        max-width: 20%;
    }

.cornerTop {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 33%;
}
    .cornerTop img{
        width: 100%;
    }

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent !important;
}
    .iconAlert {
        z-index: 2;
        width: 60%;
        max-width: 200px;
        padding: 1rem;
    }
        .iconAlert img{
            width: 100%;
        }
    .content  h1{
            color: black;
        }
    .text {
        z-index: 2;
        text-align: left;
        padding: 2rem;
    }
    .button-group {
        display: flex;
    }
    /* Boton default */
    .button-default-publish {
        color: black;
        border: 1px solid black;
        padding-top: 8px;
        padding-bottom: 8px;
        border-radius: 6px;
        width: calc(50% - 6px);
        box-shadow: 0px 0px 3px 0px #707070;
        margin-left: 3px;
        cursor: pointer;
        min-height: 40px;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
        outline: none;
        box-shadow: none;
    }
    .button-default-publish:hover {
        background: var(--focusedButtonColor);
        color: white;
        border: 1px solid var(--focusedButtonBorderColor);
    }

    /* Boton default */
    .button-default-invert-publish {
        background: var(--focusedButtonColor);
        color: white;
        border: 1px solid var(--focusedButtonBorderColor);
        padding-top: 8px;
        padding-bottom: 8px;
        border-radius: 6px;
        width: calc(50% - 6px);
        margin-left: 3px;
        cursor: pointer;
        min-height: 40px;
        font-size: 16px;
        text-align: center;
        outline: none;
        box-shadow: none;
    }

    .button-default-invert-publish:hover {
        background: transparent;
        color: black;
        border: 1px solid black;
    }

.cornerBottom {
    position: absolute;
    left: 0;
    bottom: 0;
    max-width: 33%;
}
    .cornerBottom img{
        width: 100%;
    }