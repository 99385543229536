.Playlist {
  padding: 0 3%;
}

.Playlist .Titulo {
  padding: 20px 30px 0px 5px;
  text-align: left;
  font-weight: bold;
  font-size: 2rem;
  color: var(--bannerWidgetTitleColor);
}

.Playlist .DetailTitulo {
  text-align: left;
  font-weight: bold;
  padding-left: 5rem;
  padding-top: 20px;
  font-size: 2rem;
  color: var(--bannerWidgetTitleColor);
}

.PlaylistCollection {
  max-width: 96%;
  white-space: nowrap;
  position: static;
  margin: 0;
}

.DetailPlaylistCollection {
  white-space: nowrap;
  position: static;
  overflow: hidden;
  margin: 0;
}


.PlaylistItem {
  padding: 8px;
  height: 100%;
  background-size: 25%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

.list-screen .PlaylistItem {
  padding: 0px;
}

.PlaylistHighlightItem {
  background-size: cover;
}

.PlaylistHighlightItemByTenant {
  height: 100%;
  background-size: cover;
}

.bannerClick {
  margin: 30px 0;
}

.PlaylistHighlightItemByTenant img {
  cursor: pointer;
}

.PlaylistHighlight > a {
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.PlaylistTextItem {
  display: inline-block;
  width: 100%;
  text-align: left;
  color: initial;
  border-radius: 30px 30px 10px 10px;
  position: relative;
}

.PlaylistTextItemImage {
  width: auto;
  border-radius: 14px 14px 0px 0px;
  -o-object-fit: cover;
  object-fit: cover;
}

.PlaylistCircularItem {
  display: inline-block;
  width: 100%;
  text-align: left;
  color: initial;
  border-radius: 200px;
  position: relative;
}

.PlaylistCircularItemImage {
  width: auto;
  border-radius: 200px;
  -o-object-fit: cover;
  object-fit: cover;
}

.list-screen {
  margin-bottom: 2.8rem;
}

.PlaylistCollection > ul > li {
  /*padding-left: 8px;
    padding-right: 8px;*/
}

.DetailPlaylistCollection > ul > li {
  /*  padding-left: 8px;
    padding-right: 8px; */
}

.PlaylistItemContainer {
  position: relative;
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  opacity: 1;
}

.PlaylistTextItemContainer {
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
  width: 250px;
}

.linkSpace {
  flex-grow: 1;
}
.linkSpace > .boton-play {
  max-width: 50%;
}
.list-screen .CardContainer {
  padding: 8px;
}

.CardContainer .Alpha img,
.DetailCardContainer .Alpha img {
  opacity: 0.5;
}

.CardContainer .Alpha .PlaylistItem {
  background-color: var(--backgroundColor);
  border-radius: var(--bannerWidgetBorderRadius, 15px);
  /*margin: 8px;
  padding: 0;*/
}

.PlaylistCircularItemContainer {
  position: relative;
  margin-left: 8px;
  margin-right: 8px;
  transition: transform 300ms ease-out, border-radius 600ms ease-out;
}

.react-multi-carousel-item {
  transform-style: flat !important;
}

.PlaylistItemImage {
  width: 100%;
  border-radius: var(--bannerWidgetBorderRadius, 15px);
  -o-object-fit: cover;
  object-fit: cover;
}

.ContentItemTitle {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 1rem;
  line-height: 20px;
  padding-bottom: 0.25rem;
  color: var(--bannerWidgetTextColor);
  font-family: var(--rowTextFont);
  text-overflow: ellipsis;
  overflow: hidden;
}

.PlaylistItemTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.2;
  color: var(--bannerWidgetTextColor);
  font-family: var(--rowTextFont);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 18%);
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 90%;
  padding-left: 3%;
  padding-right: 3%;
}

@media only screen and (max-width: 900px) {
  .PlaylistItemTitle {
    font-size: 1.3rem;
    line-height: 2;
  }
}

@media only screen and (max-width: 480px) {
  .PlaylistItemTitle {
    font-size: 1rem;
    line-height: 1.2;
  }
}
.PlaylistItemSubtitlePerRating {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 2.5px;
  margin-bottom: -3px;
  color: var(--bannerWidgetTextColor);
  font-family: var(--rowTextFont);
  text-align: left;
  width: 50%;
}

.PlaylistTextItemTitleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
}

.PlaylistTextItemTitle {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--bannerWidgetTextColor);
  font-family: var(--rowTextFont);
  text-align: left;
  font-weight: bold;
  margin-bottom: 0px !important;
  margin-right: 12px;
}

.PlaylistTextItemText {
  color: var(--bannerWidgetTextColor);
  font-family: var(--rowTextFont);
  text-align: left;
  font-size: 0.8rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 0;
}

.PlaylistTextItemTextHour {
  font-weight: bold;
  font-size: 0.8rem;
  margin-bottom: 0;
  color: var(--bannerWidgetTextColor);
}

.PlayListTextItemTimeContainer {
  margin-top: 6px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.PlaylistTextItemTimeIcon {
  height: 12px;
  width: 12px;
}

.PlaylistCircularItemTitle {
  width: 75%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: white;
  font-family: var(--rowTextFont);
  text-align: left;
  z-index: 1;
}

.Live .PlaylistItemTitle {
  position: absolute;
  width: 80%;
  bottom: 80px;
  color: var(--focusedButtonTextColor);
  font-size: 1rem;
  font-weight: bold;
  padding-left: 20px;
  margin-top: 0;
  text-shadow: black 0.1em 0.1em 0.2em;
  text-transform: uppercase;
  text-overflow: unset;
  white-space: normal;
}
.SpaceOriginSection {
  height: 170px;
}
.PlaylistContainer {
  position: relative;
}

.CarouselContainer {
  position: relative;
  min-height: 20rem;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.carousel {
  width: 100%;
}

.SpinnerContainer {
  position: absolute;
  min-height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  align-items: center;
}

.Live .PlaylistItemSubtitle {
  position: absolute;
  bottom: 55px;
  padding: 0 10px 0 20px;
  font-size: 0.8rem;
  color: var(--focusedButtonTextColor);
  text-shadow: black 0.1em 0.1em 0.2em;
}

.GroupWidget .PlaylistItemTitle {
  text-align: center;
  padding: 1rem;
  white-space: break-spaces;
  position: absolute;
  max-width: 100%;
  bottom: 10px;
  font-size: 10px;
  color: var(--bannerWidgetTextColor);
}

.PlaylistItemSubtitle {
  color: #888;
  max-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.PlaylistItemSubLabel {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 5px;
  font-family: var(--titleFont);
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
  display: inline-block;
  height: 16px;
}

.VideoCategory,
.VideoTag {
  text-align: left;
  width: max-content;
}

.Live .VideoCategory {
  position: absolute;
  top: 15px;
  right: 0;
  padding-right: 20px;
}

.VideoCategory .Subtag,
.VideoTag .Subtag {
  padding-left: 8px;
  padding-right: 8px;
  height: 18px;
  border-radius: 5px;
}

.VideoCategory ion-icon,
.VideoTag ion-icon {
  display: inline-block;
  height: 16px;
}

.PlaylistItem .VideoCategory {
  margin-top: -25px;
  margin-left: 5px;
}
.PlaylistItem .HighlightTagsContainer .VideoCategory {
  margin-top: 0px;
}

.PlaylistItemSubLabel3 {
  --Tagbackgroundcolor3: var(--tagBackgroundColor, #daedff);
  --Tagtextcolor3: var(--tagTextColor, #0a84ff);
  color: var(--Tagtextcolor3);
  background: var(--Tagbackgroundcolor3);
}

.PlaylistItemSubLabelV {
  --TagbackgroundcolorV: var(--tagBackgroundColor, #e8f6ee);
  --TagtextcolorV: var(--tagTextColor, #65c28f);
  background: var(--TagbackgroundcolorV);
  color: var(--TagtextcolorV);
}
.VideoType {
  position: absolute;
  top: 15px;
  right: 15px;
}

.VideoLiveTag {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #ac0505;
  color: white;
  padding: 4px 6px;
  font-size: 0.8rem;
  font-weight: bold;
}

.VideoScheduledTag {
  background-color: white !important;
  color: black !important;
}

.liveSportDesign {
  padding-left: 15px;
}
.liveSportDesign > .PlaylistItemTitle {
  font-weight: bold !important;
}

.PlaylistItemSubLabelL {
  --TagbackgroundcolorL: var(--tagBackgroundColor);
  --TagtextcolorL: var(--tagTextColor);
  color: var(--TagtextcolorL);
  background: var(--TagbackgroundcolorL);
}

.PlaylistItemSubLabelF {
  --TagbackgroundcolorF: var(--tagBackgroundColor, #fff0d9);
  --TagtextcolorF: var(--tagTextColor, #ff9d00);
  color: var(--TagtextcolorF);
  background: var(--TagbackgroundcolorF);
}

.PlaylistItemSubLabelSerial {
  --TagbackgroundcolorF: var(--tagBackgroundColor, #fff0d9);
  --TagtextcolorF: var(--tagTextColor, #ff9d00);
  color: var(--TagtextcolorF);
  background: var(--TagbackgroundcolorF);
}

.PlaylistItemSubLabelAR {
  --TagbackgroundcolorF: var(--tagBackgroundColor, #fff0d9);
  --TagtextcolorF: var(--tagTextColor, #ff9d00);
  color: var(--TagtextcolorF);
  background: var(--TagbackgroundcolorF);
}

.PlaylistItemSubLabelTag {
  --TagbackgroundcolorF: var(--tagBackgroundColor, #ffffff);
  --TagtextcolorF: var(--tagTextColor, #128605);
  color: var(--TagtextcolorF);
  background: var(--TagbackgroundcolorF);
  font-size: 12px;
}

/* Anterior e seguinte carrusel */
.Playlist .react-multiple-carousel__arrow {
  min-width: 0;
  min-height: 0;
  width: 25px;
  height: 60px;
  cursor: pointer;
  color: var(--appColor);
}

.Playlist:hover .react-multiple-carousel__arrow {
  display: inline-block;
}

.Playlist .react-multiple-carousel__arrow--left::before {
  font-size: 18px;
  width: 8px;
  font-weight: bold;
}

.Playlist:hover .react-multiple-carousel__arrow--left {
  display: inline-block;
}

.Playlist .react-multiple-carousel__arrow--left {
  left: 0;
  border-radius: 0 15px 15px 0;
}

.Playlist .react-multiple-carousel__arrow--right::before {
  font-size: 18px;
  width: 10px;
  font-weight: bold;
}

.Playlist .react-multiple-carousel__arrow--right {
  right: 0;
  border-radius: 15px 0 0 15px;
}

.progress .progress-bar {
  background-color: var(--focusColor);
  opacity: 1;
}

.BlockCarousel > ul {
  margin: 0px;
  padding: 0px;
  /*transform: translate3d(0px, 0px, 0px) !important;*/
}

.BarProgress.progress {
  height: 0.7rem;
  width: 100%;
  position: relative;
  background: rgba(92, 90, 90, 0.5);
  border-bottom-left-radius: var(--bannerWidgetBorderRadius);
  border-bottom-right-radius: var(--bannerWidgetBorderRadius);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.PlaylistHighlight img {
  max-width: 100%;
  min-width: auto;
  height: auto !important;
  cursor:pointer;
}

.PlaylistHighlightBig img {
  max-width: 100%;
  min-width: auto;
  height: auto !important;
}

.ImgHeight {
  height: 500px;
}

.TitleHighlight {
  font-family: var(--titleFont);
  color: var(--featuredWidgetTitleColor);
  font-size: 1.8rem;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  font-weight: bold;
}

.TitleHighlight:hover {
  text-decoration: underline;
}

.DescriptionHighlight {
  text-align: justify;
  font-family: var(--textFont);
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.DescHighlight {
  font-family: var(--rowTextFont);
  color: var(--featuredWidgetTextColor);
  font-size: 16px;
  text-align: justify;
  line-height: 1.5em;
  overflow: hidden;
  width: 100%;
  height: 4.5em;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.TextHome {
  width: 100%;
  padding-left: 3%;
  /*padding-right: 30px;*/
  display: flex;
  flex-direction: column;
  color: var(--textColor) !important;
}

.ButtonAccess {
  width: 100%;
  display: inline-block;
  text-align: right;
  padding-top: 40px;
}

.Live .ButtonAccess {
  position: absolute;
  left: 0;
  bottom: 10px;
  text-align: left;
  padding-left: 15px;
  margin-top: 0;
}

.ButtonAccess .Access {
  width: 150px;
  font-family: var(--rowTextFont);
  display: inline-block;
}

.Live .ButtonAccess .Access {
  min-width: 120px;
  width: auto;
  padding-left: 5px;
  padding-right: 5px;
}

.Live .button-focused {
  padding-top: 6px;
  padding-bottom: 6px;
}

ol.carousel-indicators {
  justify-content: flex-end !important;
  margin-right: 5% !important;
  margin-left: 50%;
}

.carousel-indicators {
  justify-content: flex-end !important;
  margin-right: 5% !important;
  margin-left: 50%;
}

ol.carousel-indicators li {
  max-width: 13px;
  height: 13px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: var(--textColor);
  opacity: 1;
}

.carousel-indicators button {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: var(--textColor);
  opacity: 1;
}

.carousel-indicators .active {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  opacity: 1;
  background-color: var(--focusColor);
}

.OverContainerHome {
  width: 100%;
  height: 35%;
  position: absolute;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: flex-end;
  justify-content: left;
  padding-bottom: 1rem;
  background-position: left bottom;
  background-repeat: no-repeat;
}

.HighlightRatingContainer {
  width: 20rem;
  display: flex;
  justify-content: center;
}

.HighlightRating {
  width: 60%;
}

.HighlightTagsContainer {
  display: flex;
  gap: 1rem;
  justify-content: left;
}

.HighlightInfoContainer {
  display: none;
  margin-top: 1rem;
  font-size: small;
}

.HighlightInfoContainer > *:after {
  content: " - ";
}

.HighlightInfoContainer > *:last-child:after {
  content: "";
}

.HighlightInfoContainer .Xenero {
  text-transform: capitalize;
}

.HighlightInfoContainer:hover {
  color: var(--textColor) !important;
}

.Poster .PlaylistItemFooter {
  bottom: 15px;
}

.Poster .VideoType {
  top: 30px;
  right: 30px;
}

.POSTER .VideoType {
  top: 30px;
  right: 30px;
}
.POSTER .PlaylistItemFooter {
  bottom: 0;
}

.imageDefault {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1280px) {
  .CarouselContainer {
    min-height: auto;
  }
  .ImgHeight {
    min-width: auto !important;
  }
  .OverContainerHome {
    height: 45%;
  }
}

/* Pantallas pequentas */
@media only screen and (max-width: 768px) {
  .Playlist:hover .react-multiple-carousel__arrow--left,
  .Playlist .react-multiple-carousel__arrow--left,
  .Playlist:hover .react-multiple-carousel__arrow--right,
  .Playlist .react-multiple-carousel__arrow--right {
    display: block;
  }

  .PlaylistCollection {
    position: static;
    margin-right: 5px;
    margin-left: 5px;
  }

  .DescHighlight {
    display: none;
  }

  .ButtonAccess {
    display: none;
  }

  .PlaylistHighlight {
    min-height: auto !important;
  }

  .TitleHighlight {
    font-size: 1.5rem;
  }

  .ImgHeight {
    height: auto;
    min-width: auto !important;
  }
  .OverContainerHome {
    height: 60%;
  }
}

@media only screen and (max-width: 480px) {
  .GroupWidget .PlaylistItemTitle {
    font-size: 10px;
  }
  .TitleHighlight {
    font-size: 1.15rem;
  }

  .Playlist .Titulo {
    padding: 4px 0px 0px 0px;
    font-weight: 600;
    font-size: var(--widgetTitleFontSizeMobile, 1.2rem);
  }

  .PlaylistItemImage {
    width: 100%;
  }
  
  .SpinnerContainer {
    justify-content: center !important;
  }

  .ListScreenItemTitle {
    font-size: 12px !important;
    top: 30% !important;
    text-shadow: 1px 1px 4px black, 1px 1px 4px black, 1px 1px 4px black, 1px 1px 4px black, 1px 1px 4px black !important;
  }
}

.MyCustomWidgetContainer {
  padding-top: 180px;
}

.CardContainer {
  /*transition: transform 300ms ease-out, border-radius 600ms ease-out;*/
  z-index: -1;
}

.DetailCardContainer {
  /*transition: transform 300ms ease-out, border-radius 600ms ease-out;*/
  z-index: -1;
  margin-right: 1rem;
}

.GroupWidget {
  /*  padding-bottom: 8%;*/
}

.PlaylistItemFooter {
  position: absolute;
  opacity: 0;
  background: linear-gradient(
    0deg,
    rgba(24, 24, 24, 0.9) 0%,
    rgba(24, 24, 24, 0.4) 80%,
    rgba(24, 24, 24, 0.3) 90%
  );
  bottom: 0;
  border-radius: var(--bannerWidgetBorderRadius, 15px);
  width: 96%;
  margin-left: 2%;
  margin-bottom: 2%;
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.PlaylistItemContaner {
  position: absolute;
  opacity: 6;
  background: linear-gradient(
    0deg,
    rgba(24, 24, 24, 0.9) 0%,
    rgba(24, 24, 24, 0.4) 80%,
    rgba(24, 24, 24, 0.3) 90%
  );
  bottom: 0;
  border-radius: var(--bannerWidgetBorderRadius, 15px);
  width: 96%;
  margin-left: 2%;
  margin-bottom: 2%;
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 10px;
}
.PlaylistTextItemFooter {
  background: var(--appColor);
  padding: 10px 14px;
  border-radius: 0 0 4px 4px;
  height: 100px;
}

.PlaylistCircularItemFooter {
  position: absolute;
  opacity: 0;
  background: linear-gradient(
    0deg,
    rgba(24, 24, 24, 0) 0%,
    rgba(24, 24, 24, 0.3) 10%,
    rgba(24, 24, 24, 0.8) 50%,
    rgba(24, 24, 24, 0.3) 80%,
    rgba(24, 24, 24, 0) 85%
  );
  bottom: 30%;
  height: 40%;
  left: 0;
  padding-left: 5%;
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85%;
}

.PlaylistItemGenre {
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  font-size: 14px;
  color: white;
  margin: 2.5px 0 0 5px;
}
.ListIcons {
  display: flex;
  margin: 0px 0 8px 0;
  justify-content: flex-start;
  position: relative;
}

.CardContainer:hover,
.DetailCardContainer:hover {
  position: absolute;
  z-index: 1;
}
.CardContainer:hover {
  transform: scale(1.03, 1.03);
  transition-duration: 0.2s;
  text-wrap: wrap;
}
.CardContainer-text {
  padding: 5px;
}
.CardContainer:hover .FirstItem,
.DetailCardContainer:hover .FirstItem {
  transition-duration: 0.2s;
}

.CardContainer:hover .LastItem,
.DetailCardContainer:hover .LastItem {
  transition-duration: 0.2s;
}

.CardContainer:hover .PlaylistItemImage,
.DetailCardContainer:hover .PlaylistItemImage {
  border-radius: var(--bannerWidgetBorderRadius, 15px);
}

.CardContainer:hover .PlaylistItemFooter,
.DetailCardContainer:hover .PlaylistItemFooter {
  transition-duration: 1.5s;
  opacity: 1;
}

.CardContainer:hover .PlaylistCircularItemFooter {
  transition-duration: 1.5s;
  opacity: 1;
}

.GroupWidget .CardContainer:hover .PlaylistItemImage {
  opacity: 1;
}

.HoverRating {
  width: 100px !important;
}

.HoverRatingScore {
  background: linear-gradient(
    90deg,
    rgba(255, 157, 10, 0) 7px,
    rgb(255, 157, 10) 8px
  );
  border-radius: 3px 5px 5px 3px;
  color: white;
  display: inline-block;
  height: 18px;
  line-height: 20px;
  padding: 0 5px 0 12px;
  position: relative;
  margin: 4px 10px 6px 0;
  text-decoration: none;
}

.HoverRatingScore::before {
  background: rgba(255, 255, 255, 0);
  border-bottom: 9px solid rgb(255, 157, 10);
  border-left: 8px solid transparent;
  border-top: 9px solid rgb(255, 157, 10);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
}

.ShowMoreText {
  margin-right: -200px;
  opacity: 0;
  transition-duration: 0.8s;
}

.ShowMoreText:hover {
  margin-right: 0;
  opacity: 1;
}

.iconTVG {
  position: absolute;
  height: 32px;
  width: 32px !important;
  left: 15px;
  top: 15px;
}

.iconTVGCircular {
  position: absolute;
  height: 32px;
  width: 32px;
  left: 55px;
  margin-top: 15px;
}

.IconForked {
  position: absolute;
  right: 15px;
  top: 15px;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
  padding: 20px 0;
}

@media only screen and (max-width: 1280px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 480px) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.PlaylistItemTitleGrid {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700 !important;
  line-height: 1.2;
  color: var(--bannerWidgetTextColor);
  font-family: var(--rowTextFont);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 90%;
  padding: 0 8px;
  box-sizing: border-box;
  transition: none !important;
  pointer-events: none;
  white-space: normal;
  font-size: 1.1rem;
}

.PlaylistItemTitle {
  color: var(--bannerWidgetTextColor) !important;
  transition: none !important;
}

.PlaylistItemImage:hover .PlaylistItemTitle,
.playlistItemContainer:hover .PlaylistItemTitle {
  color: var(--bannerWidgetTextColor) !important;
  transition: none !important;
}

.custom-grid-widget .grid-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
  padding: 20px 0;
}

@media only screen and (max-width: 1580px) {
  .custom-grid-widget .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
  .PlaylistItemTitleGrid {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 1080px) {
  .custom-grid-widget .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .PlaylistItemTitleGrid {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 698px) {
  .custom-grid-widget .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .PlaylistItemTitleGrid {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 480px) {
  .custom-grid-widget .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .PlaylistItemTitleGrid {
    font-size: 1.7rem;
  }
}
.custom-grid-widget .PlaylistItemImage {
  opacity: 1;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.PlaylistItemImage:hover {
  opacity: 1;
}

.PlaylistItemTitleGrid {
  color: var(--bannerWidgetTextColor) !important;
  transition: none !important;
}

.MyCustomWidgetContainer .PlaylistItemImage:hover {
  transform: translateY(-10px);
  transition: transform 0.3s ease;
  opacity: 0.5;
  box-sizing: border-box;
  border: 2px solid red;
}
