h1 {
    color: #FD4C4C;
    font-size: 3.0rem;
    font-weight: bold;
    text-align: left;
    margin: 1rem 2rem;
}

.LoginLogoContainer {
    display: flex;
    height: 150px;
    justify-content: center;
    align-items: center;
    padding: 0%;
}

.Logo {
    max-width: 100%;
    margin-left: -20px;
    height: auto;
}

.PageBackground {
    background-image: url('./../resources/login_background_tiivii.jpg');
    background-size: cover;
    background-position: center;
}

.LanguageSelector {
    position: relative;
    display: block;
    width: 20%;
    min-width: 100px;
    margin-left: 2rem;
    margin-bottom: 0.6rem;
}

.LanguageSelector select {
    width: 100%;
    padding: 0.5rem;
    border: none;
    background-color: transparent;
    color: #FFFFFF;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: 0.9rem;
    cursor: pointer;
    border-radius: 8px !important;
}

.LanguageSelector select option {
    color: black;
}

.LanguageSelector::after {
    content: '▼';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
    color: #FFFFFF;
}


.PasswordBlock {
    display: block;
    position: relative;
    width: 80%;
    margin-bottom: 0.2rem;
    margin-left: 2rem;
}

.PasswordToggle {
    color: var(--textColor);
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }

.UsernameBlock {
    display: block;
    width: 80%;
    margin-bottom: 0.5rem;
    margin-left: 2rem;
}

.LoginInput {
    background-color: #050505 !important;
    border: 1px solid #FFFFFF !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    color: #FFFFFF;
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
}

.PasswordInput {
    background-color: #050505 !important;
    border: 1px solid #FFFFFF !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    color: #FFFFFF;
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
    width: 100%;
}

.RememberRecoveryBlock {
    display: flex;
    width: 85%;
    align-items: center;
    margin-left: 2rem;
    margin-bottom: 1rem;
    justify-content: space-between;
}

.RecoveryLink {
    margin-right: 0.5rem;
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
}

.RecoveryLink:hover {
    color: #FFFFFF;
    text-decoration: underline;
}

.RememberBlock {
    display: flex;
    align-items: center;
    color: #FD4C4C;
}

.RememberBlock input {
    margin-right: 0.5rem;
}

.LoginFormButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem 1rem;
    gap: 1rem;
    background: #FD4C4C;
    border: 2px solid #FD4C4C;
    color: #FFFFFF;
    border-radius: 5px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 20px;
    text-transform: uppercase;
    width: 47.5%;
    box-shadow: none;
    border-style: solid;
}

.LoginFormButton:hover {
    background-color: gray;
    border-color: gray;
    color: white;
}

.RegisterButton {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    gap: 1rem;
    justify-content: center;
    background: transparent;
    border: 2px solid #FFFFFF;
    color: #FFFFFF;
    border-radius: 5px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 20px;
    text-transform: uppercase;
    width: 47.5%;
    box-shadow: none;
}

.RegisterButton:hover {
    background-color: gray;
    border-color: gray;
}

.ButtonGroup {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-left: 2rem;
    margin-top: 1rem;
}

.Separator {
    height: 1.5rem;
}

.NavigateAsGuestBlock {
    width: 95%;
    display: flex;
    justify-content: center;
    text-align: center;
}

.NavigateAsGuestButton {
    color: #FD4C4C;
    text-decoration: underline;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
    font-size: 1rem;
    line-height: 20px;
}

.NavigateAsGuestButton:hover {
    color: #FD4C4C;
}

@media (max-width: 1200px) {
    .LoginForm {
        width: 50%;
    }
    .LoginBackground {
        width: 50%;
    }
}

@media (max-width: 1000px) {
    .LoginForm {
        width: 60%;
    }
    .LoginBackground {
        width: 40%;
    }
}

@media (max-width: 650px) {
    .LoginForm {
        width: 100%;
    }
    .LoginBackground {
        width: 0%;
    }
}