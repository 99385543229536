.Page {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    background-color: #0E121D;
}

.PageBackground {
    width: 100%;
    height: 100%;
    background-image: url('./resources/login_background_tiivii.jpg');
    background-size: cover;
    background-position: center;
}

.Form {
    background-color: #0E121D;
    width: 100%;
    height: 100%;
    background-image: url('./resources/login_background_tiivii.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    align-items: start;
}

@media (max-width: 1200px) {
    .Form {
        width: 50%;
    }
    .PageBackground {
        width: 50%;
    }
}

@media (max-width: 1000px) {
    .Form {
        width: 60%;
    }
    .PageBackground {
        width: 40%;
    }
}

@media (max-width: 650px) {
    .Form {
        width: 100%;
    }
    .PageBackground {
        width: 0%;
    }
}