.Page {
    width: 100%;
    min-height: calc(100vh - 70px);
    display: flex;
    align-items: flex-start;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
}

.Container {
    width: 100%;
    margin-top: 3rem;
}

.Background-Image {
    min-height: 100%;
    max-width: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
}

.PageHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 0.5rem;
    margin-bottom: 2rem;
}

#LockIcon {
    width: 30px;
    height: 30px;
}

.PageTitle {
    font-weight: bold !important;
    margin: 0 !important;
    font-size: 3rem;
}

.ProductsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    height: 650px;
}

.ProductCard {
    height: 500px;
    width: 300px;
    border-radius: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: height 0.3s 0.1s, width 0.3s 0.1s;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 90%);
}

.ProductCard:hover {
    height: 650px;
    width: 380px;
}

.Gradient {
    position: absolute;
    height: 500px;
    width: 300px;
    border-radius: 2rem;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, 0) 90%);
    transition: height 0.3s 0.1s, width 0.3s 0.1s;
}

.ProductCard:hover .Gradient {
    position: absolute;
    height: 650px;
    width: 380px;
    background: linear-gradient(0deg, var(--hoverColor) 30%, rgba(0, 0, 0, 0) 90%);
}

.ProductImage {
    max-width: 100%;
    max-height: 100%;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 2rem 2rem 0 0;
}

.SpinnerContainer {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 2rem;
}


.ProductMainInfo {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ProductTitle {
    font-size: 1.2rem;
    z-index: 1;
}

.ProductCard:hover .ProductTitle {

}

.ProductPrice {
    font-size: 2rem;
    z-index: 1;
}

.ProductCard:hover .ProductPrice {

}

.ProductCurrency {
    font-size: 1rem;
    z-index: 1;
}

.ProductCard:hover .ProductCurrency {

}

.ProductIconText {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 1;

}

.TextIconLine {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

#InfoLockIcon, #InfoCamIcon, #InfoKeyIcon {
    width: 25px;
    height: 25px;
}

.ProductCard:hover #InfoLockIcon, .ProductCard:hover #InfoCamIcon, .ProductCard:hover #InfoKeyIcon {
    width: 35px;
    height: 35px;
}

.Text {
    margin: 0 !important;
    font-size: 1.2rem;
}

.ProductCard:hover .Text {
    font-size: 1.5rem;
}
.groupTitleDescription {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
@media (max-width: 768px) {
    .Page {
        width: 100%;
    }

    .ProductsContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 3rem;
        height: auto;
    }

    .ProductCard {
        height: 200px;
        /*overflow-x: auto;*/
        width: 80%;
        border-radius: 2rem;
        position: relative;
        display: flex;
        flex-direction: row;
        background-color: var(--backgroundColor);
    }

    .groupTitleDescription{
        overflow-x: auto;
        height: 210px;
        width: 100%;
        position: absolute;
        top: 5px;
        left: 0px;
    }


    .Gradient {
        height: available;
        width: 100%;
    }

    .ProductCard:hover .Gradient {
        height: 200px;
        width: 100%;
        background: linear-gradient(0deg, var(--hoverColor) 30%, rgba(0, 0, 0, 0) 90%);
    }

    .ProductCard:hover {
        height: 200px;
        width: 80%;
        background-color: var(--backgroundColor);
    }

    .SpinnerContainer {
        justify-content: flex-end;
        padding-right: 0.5rem;
        padding-top: 0.5rem;
    }

    .ProductCard:hover .ProductTitle {
        font-size: 1.2rem;
    }

    .ProductCard:hover .ProductPrice {
        font-size: 2rem;
    }

    .ProductCard:hover .ProductCurrency {
        font-size: 1rem;
    }

    .ProductImage {
        display: none;
    }
}

@media (max-width: 560px) {


    .PageHeader {
        flex-direction: column;
        justify-content: center;
    }

    #LockIcon {
        width: 20px;
        height: 20px;
    }

    .PageTitle {
        font-size: 2rem;
    }

    .ProductsContainer {
        gap: 1rem;
    }

    .ProductCard {
        min-height: 220px;
        min-width: 90%;
        border-radius: 1rem;
        flex-direction: column;
        background-color: var(--backgroundColor);
    }

    .Gradient {
        min-height: 220px;
        min-width: 100%;
        border-radius: 1rem;
    }

    .ProductCard:hover .Gradient {
        min-height: 220px;
        min-width: 100%;
        border-radius: 1rem;
        background: linear-gradient(0deg, var(--hoverColor) 30%, rgba(0, 0, 0, 0) 90%);
    }

    .ProductMainInfo {
        flex-grow: 0;
        padding: 1rem;
    }

    .ProductTitle {
        font-size: 1rem;
    }

    .ProductCard:hover .ProductTitle {
        font-size: 1rem;
    }

    .ProductPrice {
        font-size: 2rem;
    }

    .ProductCard:hover .ProductPrice {
        font-size: 2rem;
    }

    .ProductCard:hover .ProductCurrency {
        font-size: 1rem;
    }

    .ProductIconText {
        padding: 0 1rem 1rem 1rem;
        gap: 0.2rem;
    }

    #InfoLockIcon, #InfoCamIcon, #InfoKeyIcon {
        width: 15px;
        height: 15px;
    }

    .ProductCard:hover #InfoLockIcon, .ProductCard:hover #InfoCamIcon, .ProductCard:hover #InfoKeyIcon {
        width: 15px;
        height: 15px;
    }

    .Text {
        font-size: 0.8rem;
    }

    .ProductCard:hover .Text {
        font-size: 0.8rem;
    }

    .Gradient {
        height: inherit;
        background: none;
    }
    .ProductCard:hover .Gradient {
        height: inherit;
        background: none;
    }
    .ProductIconText {
        min-height: 92px;

    }
    .ProductCard {
        display: block;
    }
}