.metaData {
  font-size: 14px;
  color: var(--textColor);
}
.xenero {
}
.fecha {
}
.temporadas {
}

@media (max-width: 480px) {
  .xenero,
  .temporadas {
    display: none;
  }

  .fecha {
    font-size: 10px;
  }
}
