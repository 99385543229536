.LiveGrid {
    display: flex;
    flex-wrap: wrap;
    padding: 1%;
}

.LiveGrid .PlaylistItemContainer {
    width: 30%;
    margin: 1%;
    position: relative;
    }

.LiveGrid .PlaylistItemContainer img {
    width: 100%;
}
.LiveGrid .VideoType {
    position: absolute;
    top: 20px !important;
    right: 20px;
}
.LiveGrid .VideoCategory {
    position: absolute;
    top: 20px !important;
    right: 20px;
}

.LiveGrid .ButtonAccess {
    padding-top: 0;
    position: absolute;
    top: 60%;
    left: 10px;
}

.LiveGrid .ButtonAccess .Access {
    display: block;
}

@media only screen and (max-width: 1260px) {
    .LiveGrid .PlaylistItemContainer {
        width: 48%;
     }
    .LiveGrid .ButtonAccess {
        padding-top: 0;
        position: absolute;
        bottom: 20%;
        left: 10px;
    }
}

@media only screen and (max-width: 480px) {
    .LiveGrid .PlaylistItemContainer {
        width: 100px;
     }
}

