.ModalBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.ModalContainer {
    background-color: rgb(5, 5, 5);
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
    box-shadow: 0px 0px 8px 3px #3c3c3d;
}

.ModalTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.ButtonContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.Button {
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 0.9rem;
    margin: 0 auto;
    display: block;
}
.Button--danger {
    background-color: var(--hoverButtonColor);
    color: white;
    border: none;
}

.Button--danger:hover {
    background-color: #ff4d4d;
}
.Button--cancel {
    background-color: #ccc;
    color: black;
    border: none;
}

.Button--cancel:hover {
    background-color: #919191;
}