.ModalBackground {
  background-color: rgba(0, 0, 0, 0.6) !important;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.QRModal {
  background: var(--backgroundColor);
  color: var(--textColor);
  border-radius: var(--bannerWidgetBorderRadius);
  z-index: 9;
  padding: 40px 60px 20px 60px;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 0 8px 3px #3c3c3d;
}

.ModalContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}

.QR {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;
  background-color: white;
}

.ButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.CancelButton {
  width: 120px !important;
}
