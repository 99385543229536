@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap');

.Perfil .App-container {
    width: 100%;
    height: auto !important;
    padding: 0 3%;
}

.Perfil-container{
    text-align: center;
    margin: auto;
}

.Ranking-usuario{
    margin-top: 2rem;
}

.Perfil{
    margin-top: 8rem;
}


.Perfil .Datos-usuario{
    text-align: left;
}

.UserBlock {
    display: flex;
    align-items: flex-start;
    gap: 0 2rem;
    margin-bottom: 2rem;
    position: relative;
}

.UserBlock .UserAvatar{
    text-align: center;
    position: relative;
    margin-bottom: 2rem;
}

.UserBlock .UserInfo {
    width: 100%;
}

.UserInfoHeader {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 2rem;
}

.Username{}

.Title-bold{
    opacity: 0.65;
    font-weight: bold !important;
    font-size: 1.3rem;
    text-transform: capitalize;
}

.BlockTitle {
}

.UserInfoP {
    font-size: 1.2rem;
}

.Perfil h2 {
    font-size: 1.5rem  !important;
}
.Perfil .Datos-usuario h3 {
    font-weight: bold !important;
    font-size: 2rem  !important
}

.Perfil .Datos-usuario .Avatar-imaxe{
    height: 120px;
    width: 120px;
    border-radius: 50%;
    border: 5px solid #FFFFFF;
    opacity: 1;
}

.ProfileInterestsList{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap;

}

.ProfileSelectedInterestsList {
    margin-bottom: 1rem;
}

.Interes {
    height: auto;
    border-radius: 6px;
    padding: 5px 10px;
    margin-right: 10px;
    margin-bottom: 8px;
    color: white;
    cursor: pointer;
    text-shadow: 0px 0px 14px rgba(0,0,0,1),
        0px 0px 14px rgba(0,0,0,1),
        0px 0px 14px rgba(0,0,0,1),
        0px 0px 14px rgba(0,0,0,1),
        0px 0px 14px rgba(0,0,0,1),
        0px 0px 14px rgba(0,0,0,1),
        0px 0px 14px rgba(0,0,0,1);
}
.Unselect{
    background-color: #434343;
}

.textAlert{
    color: var(--alertBackgroundColor);
}

.Intereses-usuario {
    text-align: left;
}

.Link-datos{
    font-size: 24px;
}
.Link-datos:hover{
    cursor: pointer;
}

.LogrosContainer {
    text-align: left;
    width: 100%;
}

.LogrosContainer .List-user-logros {
    background-color: var(--rankingCellColor);
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 10px 0;
    align-items: center;
}

.LogrosContainer .List-user-logros .RewardButtonContainer {
    display: flex;
    justify-content: flex-end;
}

.LogrosContainer .List-user-logros .Titulo-logro {
    font-size: 16px;
    margin-bottom: 0;
}

.LogrosContainer .List-user-logros .Descripcion-logro {
    font-size: 12px;
}

.Perfil .Datos-usuario .Avatar-imaxe-container{
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 40px;
}

.Perfil .Datos-usuario .Avatar-imaxe{
    height: 120px;
    width: 120px;
    border-radius: 50%;
    border: 5px solid #FFFFFF;
    opacity: 1;
}

.Perfil .Datos-usuario .Avatar-points{
    background-color: white;
    border-radius: 10px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 140px;
    position: absolute;
    /* Relativo al contenedor UserBlock */
    top: 110px;
    /* Ajusta la distancia desde la parte superior del contenedor */
    left: 50%;
    /* Centrado horizontal */
    transform: translateX(-50%);
    /* Ajuste exacto al centro */
    z-index: 10;
    /* Asegúrate de que esté encima del contenido */
}

.Link-avatar{
    float: left;
}

.Perfil .Datos-usuario .Avatar-points .Icono-trophy{
    width: 30px;
    height: 30px;
    float: none;
}

.Perfil .Datos-usuario .Avatar-points span{
    color: #1D1D1F;
    font-size: 1.5rem;
}

.Ranking-usuario{
    margin-top: 3rem;
}

.Link-ranking img {
    padding: 5px;
}

.OptionsContainer {
    padding: 0 15px !important;
    margin-bottom: 1rem;
}

.OptionButtonsRow {
    display: flex;
    gap: 1rem 2rem;
    padding: 0 15px !important;
}

.ProfileOptionButton {
    border-radius: 4px;
    padding: 0.5rem 1.2rem;
    cursor: pointer;
    background-color: var(--backgroundColor);
    display: flex;
    align-items: center;
    gap: 0.75rem;
    width: 248px;
}

.ProfileOptionButton:hover {
    background-color: var(--hoverButtonColor);
    color:  var(--hoverButtonTextColor);
}

.ProfileOptionButtonDisabled {
    cursor: default;
    background-color: black;
    border-radius: 4px;
    padding: 0.5rem 2rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.EditionButton {
    border-radius: 4px;
    padding: 0.25rem 1rem;
    cursor: pointer;
    background-color: var(--backgroundColor);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    max-width: 220px;
}

.EditionButton:hover {
    background-color: var(--hoverButtonColor);
    color: var(--backgroundColor);
}

.EditionButton p {
    margin: 0;
}

.DisabledEditionButton {
    border-radius: 4px;
    padding: 0.25rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.75rem;
    max-width: 220px;
    pointer-events: none;
    opacity: 0.5;
    background-color: var(--hoverButtonBorderColor);
    color: var(--backgroundColor);
}

.DisabledEditionButton p {
    margin: 0;
}

.RewardsContainer {
    padding: 0 !important;
}


.RewardsHeader {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: flex-start;
    padding: 0 15px;
}

.RewardList {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.Row-medals {
    margin: 2rem 0!important;
}

.Icon {
    padding: 2%;
}

.Modal-dialog{
    max-width: 80% !important;
}

.Modal-dialog .ModificarUsuario{
    padding-top: 0;
}

.Modal-dialog .Register{
    min-height: auto;
}

.UserInfoInput {
    background-color: transparent !important;
    border: 1px solid var(--textColor) !important;
    border-radius: 8px !important;
    box-shadow: none !important;
    color: var(--textColor);
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
}

@media only screen and (max-width: 1024px) {

    .Perfil .App-container {
        text-align: center;
        margin: auto;
        max-width: 850px;
        padding: 0 2rem;
    }

    .Perfil .Datos-usuario .Avatar-points{
        margin-top: -5%;
    }

    .UserDataContainer {
        margin-bottom: 2rem;
    }
}

@media only screen and (max-width: 570px) {
    .LogrosContainer {
        margin:  15px 15px;
        padding: 0 15px;

    }
}
