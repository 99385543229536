.playlist {
  overflow: hidden;
  padding: 0 2rem 2rem 3%;
}

.gridWidget {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 340px));
  justify-content: flex-start;
  align-items: stretch;
  grid-gap: 1rem;
}

.carousel {
  overflow: initial;
}

@media only screen and (max-width: 768px) {
  .gridWidget {
    grid-template-columns: repeat(auto-fit, minmax(48%, 1fr));
  }
  .carousel {
    overflow: hidden;
  }
  .playlist {
    padding: 0 0.5rem 2rem 0.5rem;
  }
}

@media only screen and (max-width: 480px) {
  .gridWidget {
    grid-gap: 0px;
  }

  .playlist {
    padding: 0 0.5rem 0.7rem 0.5rem;
  }
}