.TermsModalBackground {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TermsModal {
    background: var(--backgroundColor);
    color: var(--textColor);
    border-radius: var(--bannerWidgetBorderRadius);
    z-index: 9;
    padding: 40px 60px 20px 60px;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 0px 8px 3px #3c3c3d;
}

.TermsTitle {
    font-weight: bold;
    margin-bottom: 2rem;
    font-size: 1rem;
}

.TermsContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
    width: 100%;
}

.ButtonContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap:2rem;
}

.CloseButton {
    width: 120px !important;
}