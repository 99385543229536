.playlistItemImage {
  border-radius: var(--bannerWidgetBorderRadius, 15px);
  -webkit-border-radius: var(--bannerWidgetBorderRadius, 15px);
  -moz-border-radius: var(--bannerWidgetBorderRadius, 15px);
  -ms-border-radius: var(--bannerWidgetBorderRadius, 15px);
  -o-border-radius: var(--bannerWidgetBorderRadius, 15px);
  aspect-ratio: 16/9;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.playlistItemImage__vertical {
  aspect-ratio: 16/25;
}
.barProgress {
  height: 0.5rem;
  width: 100%;
  background: var(--hoverColor);
  border-radius: 0 0 var(--bannerWidgetBorderRadius) var(--bannerWidgetBorderRadius);
  -webkit-border-radius: 0 0 var(--bannerWidgetBorderRadius) var(--bannerWidgetBorderRadius);
  -moz-border-radius: 0 0 var(--bannerWidgetBorderRadius) var(--bannerWidgetBorderRadius);
  -ms-border-radius: 0 0 var(--bannerWidgetBorderRadius) var(--bannerWidgetBorderRadius);
  -o-border-radius: 0 0 var(--bannerWidgetBorderRadius) var(--bannerWidgetBorderRadius);
}
.iconTVG {
  position: absolute;
  height: 2rem;
  width: 2rem;
  left: 1rem;
  top: 1rem;
}

.playlistHeader {
  position: relative;
  display: grid;
  border: solid 2px transparent;
}
