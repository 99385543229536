.WithMenuContainer {
    padding-bottom: 0px;
}

.CheckoutPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: calc(100vh - 110px);
}

.paymentTitle {
    font-weight: bold !important;
    margin: 0 !important;
    padding: 0px 0px 2% 0px !important;
}

.paymentTitle img{
    margin-right: 20px;
    width: 20px;
}

.modalCheckout {
    background-color: var(--backgroundColor);
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items:  center;
    flex-direction: column;
    padding: 2% 5% 3% 5%;
}
.modalCheckout .title {
    text-transform: uppercase;
    font-size: 2rem;
    color: #d72056;
    font-weight: bold !important;
    letter-spacing: 2px;
}
.Logo-checkout {
    width: 130px;
    height: 60px;
    margin-top: 5px;
    background: var(--logo) no-repeat center center;
    -webkit-background-size: contain !important;
    -moz-background-size: contain !important;
    -o-background-size: contain !important;
    background-size: contain !important;
}

.ReportOptionButtonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 4rem;
}

.CheckoutButton {
    max-width: 220px;
}
