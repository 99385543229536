.loadingScreen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0;
  background-color: black;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
