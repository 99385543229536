.playFavContainer {
  display: flex;
  align-items: bottom;
  max-width: 100%;
  display: flex;
  margin-bottom: 8px;
  flex-wrap: wrap;
}

.playFavContainer__top {
  opacity: 0;
  display: flex;
  align-items: bottom;
  gap: 2rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

#playIcon,
.favIcon {
  font-size: 2rem;
}

.linkSpace,
#playIcon {
  color: #ffffff;
}

.linkSpace:hover,
#playIcon:hover {
  cursor: pointer;
  color: var(--hoverColor);
}
