.bodyAnimation .content {
  background-color: coral !important;
}
.animation {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}
.animation > img {
  height: 100%;
  width: 100%;
}

.content {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  text-align: center;
  z-index: 15;
  padding-top: 10%;
}

.scroll {
  width: 200px;
}

.lootBoxVieira {
  width: 30%;
  margin-top: -15%;
}
.content-button {
  display: flex;
  justify-content: center;
  margin: 2rem 0;
}
.button-download {
  width: 120px;
}
