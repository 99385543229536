.ReportContent {
  color: #b3b3b3;
  display: inline-block;
}

.ReportContent:hover {
  color: var(--alertBackgroundColor);
}

.ReportContentButton {
  cursor: pointer;
}

.ReportIcon {
  font-size: 22px;
  padding-right: 5px;
}

.ReportText {
  font-size: 13px;
}

.ModalBackground {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReportContentOptions {
  background: var(--backgroundColor);
  color: var(--textColor);
  border-radius: 0.5rem;
  z-index: 9;
  padding: 2rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  display: grid;
  gap: 1rem;
}

.ReportContentSentMessage {
  padding: 1rem 2rem;
  text-align: center;
}

.SentMessageContainer {
  margin-bottom: 2rem;
}

.ReportOptionContainer {
  display: grid;
  gap: 0.5rem;
}

.ReportOption {
  width: 100%;
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0.5rem 0;
}

.ReportOption:hover {
  background: var(--focusedButtonColor);
  color: var(--focusedButtonTextColor);
}

.container {
  padding: 0 !important;
  margin: 0 !important;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark-label {
  text-align: left;
  margin: 0;
  margin-left: 2rem;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid var(--textColor);
}

.container:hover input ~ .checkmark {
  background-color: transparent;
}

.container input:checked ~ .checkmark {
  background-color: transparent;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked ~ .checkmark:after {
  display: block;
}

.container .checkmark:after {
  top: 2px;
  left: 2px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: var(--textColor);
}

.ReportOption:last-child {
  box-shadow: none;
}

form.ReportOption,
form.ReportOption:hover {
  cursor: default;
  background: var(--backgroundColor);
  color: var(--textColor);
}

.ReportOptionTitle {
  text-align: left;
  margin: 0;
}

.ReportOptionInput {
  background-color: transparent;
  border: 1px solid var(--textColor);
  border-radius: 5px;
  color: var(--textColor);
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.ReportOptionButtonContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
}
.ReportOptionForm {
  display: grid;
  gap: 1rem;
}

.CheckoutButtonContainer {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.ReportOptionButtonSend {
  width: 100%;
  border: 2px solid var(--hoverButtonColor);
  border-radius: 5px;
  font-weight: 700;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 20px;
  text-transform: uppercase;
  background-color: var(--hoverButtonColor);
  color: var(--hoverButtonTextColor);
}
.ReportOptionButtonSend:hover {
  cursor: pointer;
}
.ReportOptionButtonSend:disabled {
  cursor: default;
  color: var(--textColor);
}

.ReportOptionButtonCancel {
  width: 100%;
  border: 2px solid var(--textColor);
  border-radius: 5px;
  font-weight: 700;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 20px;
  text-transform: uppercase;
  background-color: transparent;
  color: var(--textColor);
}

.ReportOptionButton:hover {
  border: 2px solid var(--hoverButtonBorderColor) !important;
  background: var(--hoverButtonBorderColor) !important;
}

.ReportTitle {
  font-weight: 500;
  color: var(--alertBackgroundColor);
  font-size: 1.25rem;
  margin: 0;
}

.ReportSubtitle {
  font-weight: 900;
  margin: 0;
  font-size: 1rem;
}

.textDetailCotainer {
  display: flex;
  padding: 32px;
  gap: 100px;
}

.textBlock {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.textTitle {
  font-weight: bold;
}

.textInfo {
  font-size: 16px;
}
