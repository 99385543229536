.PersonaxeItem {
    padding: 5px;
}

.PersonaxeImaxe {
    border-radius: 50%;
    width: 120px;
    height: 120px;
}

.PersonaxeNome {
    font-family: var(--titleFont);
}