.BotonVer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  border: 2px solid var(--textColor);
  border-radius: 5px;
  font-weight: 700;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 20px;
  text-transform: uppercase;
  background-color: transparent;
  color: var(--textColor);
}
.BotonVerV2 {
 
  background-color: var(--defaultButtonColor);
  color: var(--defaultButtonTextColor);
  border: 2px solid var(--defaultButtonBorderColor);
}
.BotonVer svg {
  font-size: 30px;
  fill: currentColor;
}
.BotonVer:hover {
  background-color: var(--hoverButtonColor);
  color: #fff !important;
  border-color: var(--hoverButtonColor);
}
.BotonVerV2:hover {
  background-color: var(--hoverButtonColor);
  color: var(--hoverButtonTextColor);
  border-color: var(--hoverButtonBorderColor);
}

.SecondaryButton {
  border: 1px solid var(--textColor) !important;
  background-color: transparent !important;
}

.SecondaryButton:hover {
  border: 1px solid var(--hoverButtonBorderColor) !important;
  background: var(--hoverButtonBorderColor) !important;
}

.NonAtopado {
  height: 150px;
  padding-top: 70px;
  font-size: x-large;
}

/* Información xeral */
.FichaImage {
  width: 100%;
  object-fit: cover;
}

.FichaButtons {
  margin-left: 15px;
}

.VistaPrevia {
  height: 100vh;
  max-height: 500px;
  width: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.FichaOverContainer {
  width: 100%;
  /*min-height: 230px;*/
  position: relative;
  color: var(--textColor);
  text-align: left;
  padding: 2rem 3% !important;
  background-repeat: no-repeat;
}
.FichaOverContainerV2 {
  background-color: var(--backgroundColor);
}

.detailsContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.leftSide {
  justify-content: flex-start;
}

.rightSide {
  justify-content: flex-end;
}

.FichaTitle {
  font-size: xx-large;
  font-family: var(--titleFont);
  color: var(--textColor);
  padding-bottom: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.FichaTitleV2 {
  white-space: wrap;
}

.ContentAuthor {
  font-size: 1rem;
}

div.DescricionContainer {
  padding-left: 25px;
  margin-right: -20px;
}

.FichaDescricion {
  margin-bottom: 10px;
  font-family: var(--textFont);
  text-align: justify;
  line-height: 1.5em;
  overflow: hidden;
  height: 4.5em;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.InfoPrevia {
  font-size: small;
  margin-top: 5px;
  width: 100%;
}
.InfoPreviaV2 {
  margin-top: 10px;
}

.InfoPrevia > *:after {
  content: " - ";
}

.InfoPrevia > *:last-child:after {
  content: "";
}

.InfoPrevia .Xenero {
  text-transform: capitalize;
}

.VotesCategoriesContainer {
  max-width: 100%;
}
.VotesCategoriesContainerV2 {
  margin-top: 0.6rem;
  max-width: 100%;
  min-width: 300px;
  text-wrap: nowrap;
  justify-content: flex-end;
}
.CategoriesV2 {
  margin-right: 9px;
}

.VotesCategoriesContainer .Rating {
  display: inline-block;
  /*width: 240px;*/
  padding-right: 20px;
}
.VotesCategoriesContainerV2 .Rating {
  padding-right: 5px;
}

.VotesCategoriesContainer .VideoCategory,
.VotesCategoriesContainer .VideoTag {
  display: inline-block;
  vertical-align: top;
  margin-right: 7px;
  margin-bottom: 5px;
}

.VotesCategoriesContainer .VideoCategory .Subtag,
.VotesCategoriesContainer .VideoTag .Subtag {
  height: 28px;
  padding-top: 4px;
  border-radius: 12px;
}

.PlayFavContainer {
  display: flex;
  gap: 1rem;
}
.PlayFavContainerV2 {
  margin-top: 1rem;
}

.button-back a {
  font-size: 0.8rem;
  display: inline-block;
  text-decoration: underline !important;
}

.PlayFavContainer .Favorite {
  width: 50px;
  display: inline-block;
}

.Share,
.CalendarButton {
  display: grid;
  place-content: center;
  cursor: pointer;
}
.Share:hover,
.CalendarButton:hover {
  color: var(--hoverButtonColor);
}
.Share svg,
.CalendarButton svg {
  font-size: 30px;
}

.ReportContentContainer {
  padding-top: 2px;
}
.ReportContentContainerV2 {
  padding: 6px 15px 0px 6px;
  display: flex;
}

/* Reparto */
.RepartoTitle {
  text-transform: uppercase;
  padding-bottom: 5px;
  color: var(--textColor);
}

.RepartoContent {
  max-height: 75px;
  overflow: auto;
}

.RepartoContent > *:after {
  content: ", ";
}

.RepartoContent > *:last-child:after {
  content: "";
}

.playlistContainer {
  margin-bottom: 2rem;
}

.VideoDetailPage .FrameworkHome {
  margin-top: 0px;
}

.VideoDetailPage .Titulo {
  text-align: left;
  font-weight: bold;
  padding-top: 20px;
  font-size: x-large !important;
  text-transform: uppercase;
  font-family: var(--titleFont);
}

.VideoDetailPage {
  padding-bottom: 40px;
}

.principalImage {
  position: relative;
  width: 100%;
  min-height: 150px;
}
.principalImage > img {
  z-index: 1;
  max-width: 100%;
  min-width: auto !important;
  cursor: pointer;
}
.contentPrincipalImage {
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.contentPrincipalImage .FichaOverContainer {
  padding-left: 3% !important;
}

.VideoDetailPage .VotesCategoriesContainer {
  display: flex;
}

.VideoDetailPage .VotesCategoriesContainer .VideoType {
  position: relative;
  top: 1px;
  right: 0;
  margin-right: 5px;
}
.VideoDetailPage .VotesCategoriesContainer {
  display: flex;
}

.VideoDetailPage .VotesCategoriesContainer .VideoType {
  position: relative;
  top: 1px;
  right: 0;
  margin-right: 5px;
}

.VideoDetailPage .VotesCategoriesContainer .VideoType .typeIcon {
  height: 28px;
}
.VideoDetailPage .VotesCategoriesContainer .VideoType .typeIcon img {
  height: 100%;
}

.VideoDetailPage .VotesCategoriesContainer .VideoType .typeIcon img {
  max-height: 28px;
}

.FichaTecnica {
  text-align: left;
  margin-left: 10px;
  padding-top: 10px;
}

.textDetailContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 3rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
  margin-left: 6rem;
}
.textDetailContainerV2 {
  margin-left: 0.7rem;
}

.TextTitle {
  font-family: var(--titleFont);
  margin-top: 1.3rem;
  text-align: left;
}

.TextTitle:first-child {
  font-family: var(--titleFont);
  margin-top: 2.6rem;
}

.SubText {
  font-family: var(--textFont);
}

.FichaCasting {
  margin-top: 5rem;
}

.FichaInfoContainer {
  margin-top: 3rem;
  width: 100%;
}

.Framework {
  background: var(--detailPageBgImage);
  background-color: var(--detailPageBgColor);
  width: 100%;
  height: 220px;
  position: absolute;
  left: 0;
  text-align: left;
}

.SpinnerVideoDetail {
  width: 100%;
  position: relative;
  text-align: center;
  float: left;
  margin-top: 20%;
}

.SeeMore {
  width: 100px;
  height: 25px;
  float: right;
  text-align: center;
  margin-right: 10px;
}

.SeeMore:hover {
  background: var(--hoverButtonColor);
  color: var(--hoverButtonTextColor);
  border-color: var(--hoverButtonBorderColor);
  border-radius: 5px;
  width: 100px;
}

.contentContainer {
  padding: 2rem 3% !important;
}

.DescriptionTitle {
  font-weight: bold;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 6rem;
  font-size: x-large;
}
.DescriptionTitleV2 {
  margin-left: 0.7rem;
}

.DescriptionDetail {
  text-align: left;
  font-family: var(--textFont);
  font-size: 16px;
  margin-bottom: 2rem;
  margin-right: 6rem;
  white-space: pre-line;
}
.DescriptionDetailV2 {
  margin-left: 0.7rem;
}

.CommentsContainer {
  max-width: 700px;
  padding-bottom: 50px;
}

.FichaInfoDetail {
  margin-left: 20px;
  margin-right: -20px;
}

.alignLeft {
  text-align: left;
}

.buttonDisabled {
  opacity: 0.4;
}

.Share {
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border: 2px solid var(--defaultButtonBorderColor);
  border-radius: 5px;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.2;
  text-transform: uppercase;
  background-color: var(--defaultButtonColor);
  color: var(--defaultButtonTextColor);
  text-align: center;
  height: 50px;
  width: 60px;
  max-width: 100%;
  box-sizing: border-box;
}

.Share:hover {
  cursor: pointer;
  color: var(--hoverButtonColor);
}

@media (max-width: 870px) {
  .detailsContainer {
    flex-direction: column;
    gap: 15px;
    justify-content: flex-start;
  }
  .VotesCategoriesContainerV2 {
    justify-content: flex-start;
  }
  .ReportContentContainerV2 {
    padding: 9px 0px 0px 2px;
    justify-content: flex-start;
  }
}

@media (max-width: 768px) {
  .contentContainer {
    min-height: auto;
  }
  .FichaOverContainer {
    padding: 0rem 1rem !important;
  }
  .FichaOverContainerV2 {
    padding: 0.5rem 1rem !important;
  }
  .principalImage {
    display: flex;
    flex-direction: column;
    min-height: 35vh;
  }
  .contentContainer {
    padding: 0rem 3% !important;
    min-height: 20rem;
  }
}

@media only screen and (max-width: 480px) {
  .BotonVerV2 {
    width: 160px;
  }

  .DescriptionDetail {
    margin-right: unset;
  }

  .DescriptionTitle {
    margin-right: unset;
  }
  
  .PlayFavContainerV2 {
    flex-direction: row;
    grid-gap: 0.6rem;
  }
}
