.button {
    position: relative;
}

.button2 {
    float: right;
}

.EditButton {
}

.ImputComment {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 1rem;
}

.ButtonEllipsis ion-icon {
    color: var(--textColor) !important;
}

.InputName {
    border-radius: 8px;
    background-color: transparent;
    border: 1px solid dimgrey !important;
    box-shadow: none !important;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
}

.ImputCommentEdit {
    width: calc(100% - 80px);
    display: inline-block;
}

.ItemSubtitleEdit {
    max-height: 150px;
}

.ImputComment .InputButton {
    position: relative;
    width: 100px !important;
    display: inline-block;
}

.CommentButton {
    margin: 0 !important;
    border-radius: 0 5px 5px 0 !important;
    padding: 0 20px !important;
}

.CommentEditButton {
    display: inline-block;
    width: 70px;
}

.CommentEditButton .button {
    width: 70px;
}

.ShowMoreButton {
    display: block;
    float: right;
}

.CommentsUsers {
    margin-top: 1rem;
}

.Comments {
    text-align: left;
    font-family: var(--textFont);
}

.Comment2 {
    width: 100%;
    margin-top: 20px;
    margin-left: 2px;
}

.ItemTitle {
    font-family: var(--textFont);
    font-weight: bold;
    color: var(--textColor);
    display: inline-block;
    padding-right: 10px;
    font-size: 1.5rem;
}

.ItemSubtitle {
    font-family: var(--textFont);
    width: 120%;
}

.PublishDate {
    font-family: var(--textFont);
    font-size: xx-small;
    display: inline-block;
}

.UsuarioComent {
    width: 40px;
    height: 40px;
    display: none;
}

.AvatarComent {
    width: 40px;
    height: 40px;
    margin-top: 40px;
}

.TopBarComment {
    width: 100%;
    position: relative;
    margin-bottom: 8px;
}

@media only screen and (max-width: 480px) {
    .ItemSubtitle {
        width: 100%;
    }


}