.ProfileOptionButton--danger {
    background-color: var(--hoverButtonColor);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 40px;
    padding: 0.5rem 1.2rem;
    width: 248px;
    display: flex;
    /* Activar flexbox */
    justify-content: center;
    /* Centrar horizontalmente */
    align-items: center;
    /* Centrar verticalmente */
    text-align: center;
    /* Por si hay texto largo */
}

.ProfileOptionButton--danger:hover {
    background-color: #ff4d4d;
}

.ModalBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.ModalContainer {
    background-color: rgb(5, 5, 5);
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
    box-shadow: 0px 0px 8px 3px #3c3c3d;
    border-radius: var(--bannerWidgetBorderRadius);
}
.modal-content {
    background-color: rgba(0, 0, 0, 0.8) !important;
    color: #fff;
    border: none;
  }

.ModalTitle {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.ModalMessage {
    font-size: 16px;
    margin-bottom: 20px;
}

.ButtonContainer {
    display: flex;
    justify-content: center !important;
    gap: 20px;
    margin-top: 20px;
}

.Button {
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 0.9rem;
}

.Button--cancel {
    background-color: #ccc;
    color: black;
    border: none;
}

.Button--cancel:hover {
    background-color: #bbb;
}

.Button--danger {
    background-color: var(--hoverButtonColor);
    color: white;
    border: none;
}

.Button--danger:hover {
    background-color: #ff4d4d;
}