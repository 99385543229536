.PuntuacionObtida{
    padding-top: 50px;
    width: 100%;
}

.Votos{
    float:right;
}

.Estrelas {
    position: relative;
    height: 50px;
    width: 100%;
    margin-top: -5px;
    display: flex;
    height: 50px;
}
.Estrelas .ratingValue {
    background: linear-gradient(90deg, rgba(255, 157, 10, 0) 7px, rgb(255, 157, 10) 8px);
    border-radius: 3px 5px 5px 3px;
    color: white;
    display: inline-block;
    height: 28px;
    line-height: 20px;
    padding: 4px 10px 4px 12px;
    position: relative;
    margin: 5px 10px 6px 4px;
    text-decoration: none;
}

.Estrelas .ratingValue::before {
    background: rgba(255, 255, 255, 0);
    border-bottom: 14px solid rgb(255, 157, 10);
    border-left: 8px solid transparent;
    border-top: 14px solid rgb(255, 157, 10);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 28px;
}

.Estrelas .noRatingValue {
    background: linear-gradient(90deg, rgba(255, 157, 10, 0) 7px, rgb(255, 157, 10) 8px);
    border-radius: 3px 5px 5px 3px;
    color: white;
    display: inline-block;
    height: 28px;
    line-height: 20px;
    padding: 4px 10px 4px 12px;
    position: relative;
    margin: 5px 10px 6px 4px;
    text-decoration: none;
}


.Estrelas .noRatingValue::before {
    background: rgba(255, 255, 255, 0);
    border-bottom: 14px solid rgb(255, 157, 10);
    border-left: 8px solid transparent;
    border-top: 14px solid rgb(255, 157, 10);
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 28px;
}


.Estrelas span{
    width: 20%;
    text-align: center;
}

.react-stars {
    width: 100%;
    outline: none;
}

.RatingTitulo {
    font-size: larger;
    text-transform: uppercase;
    font-family: var(--titleFont);
}

.PuntuacionTotal {
    font-size: x-large;
    font-weight: bold;
    padding-right: 5px;
}

.Votos {
    float: right;
    padding-top: 8px;
}

@media (max-width: 768px) {
    .Estrelas .noRatingValue {
        font-size: 0.8rem;
    }
}