.Ranking {
    width: 100%;
    height: auto !important;
    padding-right: 0;
    padding-left: 0;
    margin: 0;
}

.Ranking-container {
    padding: 3%;
}

.Icono {
    width: 48px;
    height: 48px;
}

.Ranking .Datos-usuario {
    background: var(--rankingUserBannerColor) 0 0 no-repeat padding-box;
    padding: 40px;
}

.Ranking .Datos-usuario p {
    text-align: left;
}

.Ranking .Datos-usuario .Avatar {
    margin: auto;
    position: relative;
}

.Ranking .Datos-usuario .Avatar-imaxe-container {
    display: flex;
    justify-content: center;
}

.Ranking .Datos-usuario .Avatar-imaxe {
    height: 220px;
    width: 220px;
    border-radius: 50%;
    border: 5px solid #FFFFFF;
}

.Ranking .Datos-usuario .Avatar-points {
    background-color: white;
    margin: 0 auto;
    border-radius: 10px;
    height: 50px;
    margin-top: -10%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    width: 160px;
    position: relative;
    z-index: 1;
}


.Ranking .Datos-usuario .Avatar-points .Icono-trophy {
    width: 40px;
    height: 40px;
    float: none;
}

.Ranking .Datos-usuario .Avatar-points span {
    color: #1D1D1F;
    font-size: 1.5rem;
}

@media (max-width: 768px) {
    .Ranking .Datos-usuario {
        padding: 20px;
        width: 100%;
        margin: 0 15px;
    }
}

.User-info-cards {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 20px;
}

.Ranking .Datos-usuario .User-info {
    background: #FFFFFF 0 0 no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    text-align: left;
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
}

.Ranking .Datos-usuario .User-info span {
    color: #555555;
    padding-left: 20px;
    flex-grow: 1;
}

.Ranking .Datos-usuario .User-info .Posicion {
    color: #EC407A;
    padding: 0 15px;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: right;
}


.User-achievement-text {
    display: flex;
    flex-direction: column;
}

.User-next-achievement {
    font-weight: bold;
}


.Ranking .Datos-usuario .User-info .Total {
    float: right;
}

.Ranking .Datos-usuario .User-name {
    text-align: left;
    font-weight: bold;
    font-size: 2.0rem;
}

.Logros {
    text-align: left;
    width: 100%;
}

.row .List-user-logros {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.Logros-title {
    margin-top: 20px;
    font-size: 1.7rem;
    font-weight: bold;
}

.Logros-subtitle {
    font-size: 1rem;
}

.Full-list-logros {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.Logros .List-user-logros {
    background-color: var(--backgroundColor);
    border-radius: 5px;
    padding: 10px 0;
    align-items: center;
}

.Logros .List-user-logros .RewardButtonContainer {
    display: flex;
    justify-content: flex-end;
}

.Logros .List-user-logros .Titulo-logro {
    font-size: 16px;
    margin-bottom: 0;
}

.Logros .List-user-logros .Descripcion-logro {
    font-size: 12px;
}


@media (max-width: 768px) {
    .Logros {
        margin: 30px 15px;
        padding: 0 15px;
        width: 100%;
    }

    .Logros-subtitle {
        text-align: center;
    }

    .Logros .List-user-logros .Descripcion-logro {
        margin-bottom: 0;
    }
}

.Listado-ranking {
    text-align: left;
    margin: 0 15px;
    height: 100%;
}

.Listado-ranking .List-logros {
    background: var(--rankingCellColor) 0 0 no-repeat padding-box;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    opacity: 1;
    padding: 10px;
    margin-bottom: 20px;
    align-items: center;
}

.Listado-ranking .List-logros span {
    margin-left: 10px;
}

.Listado-ranking h2 {
    margin-bottom: 40px;
    font-weight: bold;
}

.Ranking .List-logros .Posicion-user {
    font-weight: bold;
    font-size: 1.5rem;
}

.Ranking .List-logros .Nombre-user {
    font-size: 1.25rem;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
}


.Ranking .List-logros .Total {
    float: right;
    font-weight: bold;
    font-size: 1.5rem;
    margin-right: 2rem;
}

.Ranking .List-logros .Avatar {
    margin: auto;
}

.Ranking .List-logros .Avatar-imaxe {
    margin-left: 15px;
    margin-right: 15px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
    opacity: 1;
    z-index: -3000;
}

.Ranking .List-logros .Avatar-list {
    /*margin: 0;*/
}

@media (max-width: 992px) {
    .Listado-ranking {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .Listado-ranking {
        margin: 30px 15px;
        padding: 0 15px;
        width: 100%;
    }

    .Ranking .List-logros .Posicion-user {
        font-weight: bold;
        font-size: 1rem;
    }

    .Ranking .List-logros .Nombre-user {
        font-size: 1.2rem;
    }

    .Ranking .List-logros .Total {
        font-weight: bold;
        font-size: 1rem;
        margin-right: 10px;
    }

    .Listado-ranking .List-logros .Icono-trophy {
        width: 25px;
        height: 25px;
    }
}


.Avatar-imaxe-container-list {
    display: flex;
    justify-content: center;
}

.Avatar-imaxe-list {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    border: 2px solid #FFFFFF;
}

.Icono-trophy {
    width: 26px;
    height: 26px;
    margin-top:6px ;
}

.Mobile-menu {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.Mobile-menu-item {
    width: 100%;
    margin-bottom: 0 !important;
    padding-bottom: 8px;
    border: none;
    border-bottom: 2px solid transparent;
    color: var(--appColor);
    outline: none !important;
}

.Mobile-menu-item-selected {
    width: 100%;
    margin-bottom: 0 !important;
    padding-bottom: 8px;
    border: none;
    border-bottom: 2px solid var(--textColor);
    color: var(--textColor);
    background-color: var(--backgroundColor);
    outline: none !important;
}

@media (max-width: 1024px) {
    .Ranking .Datos-usuario {
        padding: 10px;
    }

    .Ranking .Datos-usuario .Avatar-imaxe {
        height: 160px;
        width: 160px;
        border-radius: 50%;
        border: 5px solid #FFFFFF;
    }
}

.Logro-finished {
    color: #1D1D1F;
}

.Logro-progress-bar-user {
    height: 100%;
    background-color: orange;
    border-radius: 5px
}

.Logro-progress-bar-total {
    margin-left: 20px;
    height: 10px;
    background-color: gray;
    border-radius: 5px;
}

@media only screen and (max-width: 480px) {
    .Ranking {
        display: flex;
        justify-content: center;

    }
    .RankingMobile {
        display: flex;
        flex-direction: row;
    }
    .Ranking-container {
        padding: 0 0;
        margin: 0;
        width: 89%;
    }

    .Icono {
        width: 32px;
        height: 32px;
    }

    .Ranking .Datos-usuario {
        padding: 20px;
        width: 100%;
        margin: 0 15px;
    }

    .Ranking .Datos-usuario .Avatar-imaxe {
        height: 120px;
        width: 120px;
        border-radius: 50%;
        border: 5px solid #FFFFFF;
    }

    .Ranking .Datos-usuario .Avatar-points {
        background-color: white;
        margin: 0 auto;
        border-radius: 5px;
        height: 40px;
        margin-top: -25%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        width: 80px;
    }

    .Ranking .Datos-usuario .Avatar-points .Icono-trophy {
        width: 30px;
        height: 30px;
        float: none;
    }

    .Ranking .Datos-usuario .Avatar-points span {
        color: #1D1D1F;
        font-size: 1rem;
    }
    
    .LogrosContainer {
        width: 90%;
    }

}