.error-page {
  height: 100vh;
  display: flex;
  color: var(--textColor);
}

.error-page-dark {
  background: url("../../Resources/Images/Web1920-error-dark.jpg") no-repeat center center;
}

.error-page-light {
  background: url("../../Resources/Images/Web1920-error-light.jpg") no-repeat center center;
}

.text-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 6rem;
  color: var(--textColor);
}

.error-title {
  font-size: 3rem;
  font-weight: 700 !important;
}

.error-subtitle {
  font-size: 2rem;
}

@media (min-width: 200px) {
  .error-page-dark {
    background: url("../../Resources/Images/Mobile1440-error-dark.png") no-repeat center center;
    background-size: cover;
  }

  .error-page-light {
    background: url("../../Resources/Images/Mobile1440-error-light.png") no-repeat center center;
    background-size: cover;
  }

  .error-title {
    font-size: 1.25rem;
  }

  .error-subtitle {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .text-block {
    margin-left: 0;
    padding: 0 20px;
    justify-content: flex-end;
    margin-bottom: 50px;
    width: 100%;
  }

  .error-title {
    font-size: 1.5rem;
  }

  .error-subtitle {
    font-size: 1.25rem;
  }
}

@media (min-width: 768px) {
  .error-page-dark {
    background: url("../../Resources/Images/Web1920-error-dark.jpg") no-repeat center right;
    background-size: cover;
  }

  .error-page-light {
    background: url("../../Resources/Images/Web1920-error-light.jpg") no-repeat center right;
    background-size: cover;
  }

  .text-block {
    margin-left: 0;
    padding: 0 20px;
    justify-content: flex-end;
    margin-bottom: 50px;
    width: 100%;
  }

  .error-title {
    font-size: 2rem;
  }

  .error-subtitle {
    font-size: 1.75rem;
  }
}

@media (min-width: 1400px) {
  .error-page-dark {
    background: url("../../Resources/Images/Web1920-error-dark.jpg") no-repeat center right;
    background-size: cover;
  }

  .error-page-light {
    background: url("../../Resources/Images/Web1920-error-light.jpg") no-repeat center right;
    background-size: cover;
  }

  .text-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-left: 6rem;
  }

  .error-title {
    font-size: 2.5rem;
  }

  .error-subtitle {
    font-size: 2rem;
  }
}
