.Alert {
  z-index: 30;
  position: absolute;
  top: 200px;
  right: 0;
}

.Alert .notification {
  margin-bottom: 8px;
  min-height: 30px;
  border-radius: 8px;
  opacity: 1;
  z-index: 10;
}

.Alert .notification::before {
  font-size: 24px;
  margin-top: -13px;
}

.Alert .notification-container {
  width: auto !important;
  top: 50px;
}

/* Success message */
.Alert .notification-success {
  background-color: var(--hoverColor);
  color: var(--textColor);
}

.Alert .notification-success::before {
  display: none;
}
.notification {
  padding: 1rem;
  padding-left: 3rem;
}
/* Mobile version */
@media only screen and (max-width: 549px) {
  .Alert {
    top: 0px;
    left: 0px;
    width: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
  }
  .notification-container {
    width: 100%;
    position: relative;
  }
}
