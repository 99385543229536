.SearchBarContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.SearchContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  border: 1px solid white;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

.SearchContainer .MuiAutocomplete-inputRoot .MuiInputBase-input {
  border: none;
  padding: 6px 0;
}

.MuiAutocomplete-input:first-child {
  background: none;
  border: none;
  color: white;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  box-shadow: none;
}

.MuiInput-underline:before {
  border-bottom: none !important;
}

.MuiInput-underline:after {
  border: none !important;
}

.labelSearch {
  width: 100%;
}

.Barra-Busqueda .InputNome {
  font-family: var(--titleFont), sans-serif;
  width: 230px;
}

.IconSearch {
  font-size: 24px;
  line-height: 1;
}
.BarraBusquedaMobil {
  width: 0;
  overflow: hidden;
  transition: width 0.25s ease-in-out;
  -webkit-transition: width 0.25s ease-in-out;
  -moz-transition: width 0.25s ease-in-out;
  -ms-transition: width 0.25s ease-in-out;
  -o-transition: width 0.25s ease-in-out;
}
.BarraBusquedaMobil.active {
  width: 300px;
  overflow: visible;
}
/* Pantallas pequenas */
@media only screen and (max-width: 768px) {
  .BarraSuperiorUsuario .Linguas {
    display: none;
  }

  .BarraSuperiorUsuario .Usuario {
    display: inline-block;
    float: none;
  }

  .BarraBusquedaMobil {
    background-color: var(--backgroundColor);
    position: absolute;
    left: 2rem;
    top: 3.5rem;
  }
  .BarraBusquedaMobil.active {
    width: calc(100vw - 4rem);
  }

  .BarraBusquedaMobil .IconSearch ion-icon {
    color: var(--focusedButtonTextColor);
    margin-left: 0;
  }

  .BarraSuperior .AvatarMenu {
    margin-top: -50px;
  }
}

.IconSearhMobile {
  cursor: pointer;
  font-size: 1.5rem;
  display: grid;
}

.IconSearhMobile .IconSearch {
  margin: 0;
}

.IconSearhMobile ion-icon {
  cursor: pointer;
}

.PecharBarraBusqueda {
  font-size: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 480px) {
  .IconSearhMobile .IconSearch {
    padding-top: 0;
    padding-left: 0;
  }

  .SearchBarWrapper {
    flex: none !important;
  }

  .IconSearhMobile {
    display: flex;
    align-items: center;
    margin-right: 0;
  }

  .PecharBarraBusqueda {
    padding-right: 0;
  }

  .Barra-Busqueda {
    width: 100%;
    left: 0;
  }

  .Barra-Busqueda {
    width: 100%;
    left: 0;
  }
}
