.InfoPanel {
  background: var(--focusColor);
  display: none;
  color: var(--defaultButtonTextColor);
  padding: 10px;
  position: absolute;
  z-index: 999;
}

.InfoPanel.Mostrar {
  display: block;
}

.InfoPanelButton {
  color: var(--focusedButtonColor);
  cursor: pointer;
  display: inline-block;
}

.FondoInfoPanel {
  position: fixed;
  z-index: 900;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}

.FondoAtravesable {
  pointer-events: none;
}
