.SessionModalBackground {
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.SessionModal {
  background: var(--backgroundColor);
  color: var(--textColor);
  border-radius: var(--bannerWidgetBorderRadius);
  z-index: 9;
  padding: 40px 60px 20px 60px;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  box-shadow: 0 0 8px 3px #3c3c3d;
}

.LoginTextContainer {
  margin-bottom: 1.5rem;
}

.SessionTitle {
  font-weight: bold;
  font-size: 1.5rem;
}

.LoginButtonContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--headerIconColor);
}
.LoginButtonContainer:hover {
  color: var(--headerHoverIconColor);
}

.LoginModalButton {
  width: 160px !important;
}
