.Search .App-header{
    height: 90px;
}

.SearchVideo {
    padding-right: 15px;
    padding-bottom: 15px;
    vertical-align: top;
    display: flex;
    justify-content: center;
    flex-grow: 0 !important;
}

.SearchImg {
    width: 320px;
    height: auto;
}
.SearchImg .VideoType{
    right: 25px;
    top: 5px;
}

.PlaylistItemSearch {
    display: inline-block;
    width: 100%;
}

.PlaylistItemImageSearch {
    height: 188px;
    border-radius: var(--bannerWidgetBorderRadius, 15px);
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
}

.PlaylistItemTitleSearch {
    text-align: left;
    color: var(--rowTextColor);
    font-family: var(--rowTextFont);
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-top: 8px;
}

.Results {
    padding-top: 15px;
    padding-bottom: 1rem;
    display: inline-block;
    font-family: var(--titleFont);
    font-size: 2rem;
    color: var(--rowTitleColor);
    width: 100%;
    text-align: left;
    font-size: 1.7rem;
    font-weight: bold;
}

.clarifyLayer {
    padding-top: 12px;
    padding-bottom: 2px;
}

.resultsText {
    padding-left: 30px;
}

.PlaylistContainerSearch {
    width: 100%;
    text-align: left;
    padding-left: 30px;
    padding-right: 15px;
}

.contentSearch {
    width: 100%;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
}

.NoResultsImage {
    padding-top: 100px;
    width: 27%;
}

.NoResultsText1 {
    margin-top: 1rem;
    width: 95%;
    text-align: center;
    font-family: var(--titleFont);
    font-size: 2.3rem;
}

.NoResultsText2 {
    margin-top: 20px;
    width: 95%;
    text-align: center;
    font-family: var(--textFont);
    font-size:1.5rem;
}

@media only screen and (max-width: 600px) {

    .NoResultsImage {
        width: 40%;
    }

    .Results {
        padding-top: 40px;
    }

}

@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .Results {
        padding-top: 80px;
    }
}