.DiplomaContainer {
    background-color: #F46E42;
    border-radius: 100%;
    padding: 0.5rem;
    width: 48px;
    height: 48px;
}

.DiplomaIcon {
    width: 100%;
    height: 100%;
}