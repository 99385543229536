.botonFav {
  display: flex;
  font-size: 2rem;
  cursor: pointer;
}
.isFav {
  color: var(--hoverColor);
}
.botonFav:hover ion-icon {
  color: var(--hoverColor);
}
