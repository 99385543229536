.languages .tooltip-container {
  position: relative;
  display: inline-block;
}

.languages .tooltip-container .tooltip-text {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
  z-index: 1;
  font-size: 0.8rem;
}

.languages .tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}