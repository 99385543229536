.Header {
    height: 150px;
    background-color: var(--catalogueHeadBackgroundColor);
}

.Catalogo {
    width: 100%;
}

.Catalogo .VideoCategory {
    padding-top: 7px;
    padding-bottom: 13px;
}

.Catalogo .contentFooter {
    bottom: 0px !important;
}
.Catalogo .contentFooter .typeIcon {
    margin-top: -12px;
}

.Discover {
    margin-left: 3%;
    font-size: 50px;
    color: var(--textColor);
    width: 97%;
    text-align: left;
    font-family: var(--titleFont);
    padding-top: 35px;
    padding-bottom: 15px;
}

.Catalogo .Buttons {
    text-transform: uppercase;
    padding-left: 30px;
    text-align: left;
}

.InputCatalogue {
    width: 100%;
    font-family: var(--titleFont);
    text-align: left;
    display: flex;
}

.OrderMenuContainer {
    text-align: right;
    width: 100%;
}

.InputCatalogue .InputCatalogue2 {
    width: 370px;
    padding-left: 40px;
    margin-left: -30px;
    top: 0;
    position: absolute;
}

.InputSearchCatalogue {
    position: relative;
    margin-top: 25px;
}

.IconSearchCatalogue {
    padding-top: 10px;
    position: relative;
    width: 30px;
    display: inline-block;
    text-align: center;
    z-index: 9;
}

.CatalogueVideo {
    display: inline-block;
    text-align: left;
    width: 100%;
}

.Catalogo .infinite-scroll-component {
    overflow: visible !important;
    text-align: left;
    padding-top: 30px;
    padding-left: 23px;
    padding-right: 23px;
}

.PlaylistItemImageSearch {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.PlaylistItemCatalogue {
    display: inline-block;
    position: relative;
    vertical-align: top;
}

.VideoContainer {
    cursor: pointer;
}

.SpinnerCatalogue {
    width: 100%;
    position: relative;
    text-align: center;
    float: left;
}

.NoResultsSearch {
    text-align: center;
    width: 100%;
}

.ImageNoResult {
    width: 30%;
}

.NoResults {
    font-family: var(--filterTitleColor);
}

.OrderMenu {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    width: 230px;
    display: inline-block;
}

.MenuCatalogueContainer {
    color: var(--filterTitleColor);
    display: inline-block;
    cursor: pointer;
}

.MenuCatalogueContainer ion-icon {
    color: var(--filterTitleColor) !important;
}

.MenuDesplegableOrder {
    position: absolute;
    z-index: 9;
    background: var(--backgroundColor);
    font-size: medium;
    border-radius: 15px;
    box-shadow: 0px 1px 3px 0px #9b9b9b;
    padding: 5px;
    padding-top: 12px;
}

.MenuDesplegableOrder .MenuSubsectionOrder {
    display: block;
    text-align: left;
    padding-top: 8px;
    padding-bottom: 10px;
    padding-left: 5px;
    min-width: 170px;
}

.MenuDesplegableOrder .MenuSubsectionOrder:hover, .MenuDesplegableOrder .MenuSubsectionOrder.Selected {
    color: var(--textColor) !important;
    border-left: 3px solid var(--appColor);
    background-color: var(--hoverColor);
}

.MenuDesplegableOrder .MenuSubsectionOrder:hover:after, .MenuDesplegableOrder .MenuSubsectionOrder.Selected:after {
    content: "";
    opacity: 0.2;
    display: block;
    height: 42px;
    width: calc(100% - 8px);
    position: absolute;
    margin-top: -32px;
    margin-left: -20px;
}

.Catalogo .Breadcrumbs {
    padding-bottom: 15px;
}

.Content .PlaylistItemContainer {
    position: relative;
    overflow: visible;
    margin: 1rem !important;
    padding: 0 !important;
}

.contentFooter {
    position: absolute;
    opacity: 0;
    background: linear-gradient(
            0deg, rgba(24, 24, 24, 1) 0%, rgba(24, 24, 24, 0.5) 90%, rgba(24, 24, 24, 0) 100%);
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 0 0 5px 5px;
    padding: 0px 25px 5px 25px;
}

.Content .PlaylistItemContainer:hover .contentFooter {
    transition-duration: 1.5s;
    opacity: 1;
}

.Content .PlaylistItemContainer:hover {
    transform: scale(1.18, 1.18);
    transition-duration: 0.2s;
    z-index: 1;
}

.galleryGrid {
    display: flex;
    justify-content: normal;
    align-items: flex-start;
    flex-wrap: wrap;
}


/* Pantallas pequentas */
@media only screen and (max-width: 480px) {
    .galleryGrid {
        justify-content: space-evenly;
        gap: 3px;
    }

    .Catalogo .InputCatalogue .InputCatalogue2 {
        width: 100%;
        position: relative;
    }

    .Catalogo .OrderMenu {
        padding-right: 0;
    }

    .Catalogo .Buttons {
        overflow: auto;
        white-space: nowrap;
    }

    .Catalogo .Buttons a {
        display: inline-block;
    }

    .Catalogo .PlaylistItemContainer {
        padding-left: 0;
        padding-right: 0;
    }
}

