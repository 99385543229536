.rating {
  display: flex;
  flex-wrap: wrap;
}
.rating div p {
  background: linear-gradient(90deg, rgba(255, 157, 10, 0) 7px, rgb(255, 157, 10) 8px);
}
.playlistItemSubtitlePerRating {
}
.subtitle {
}
