.button-text {
    padding-right: 10px;
}
.containerWidget {
    width: 100%;
}
.FrameworkHomeBackgroundContainer {
    background: var(--bannerWidgetImageColor);
}

.FrameworkHomeBackground {
    height: 170px;
    background-color: var(--bannerWidgetBgColor);
}
.FrameworkHomeBackgroundV2 {
    height: 170px;
}

.FrameworkHome {
    width: 100%;
    margin-top: -164px;
    position: relative;
    text-align: left;
}

.Home {
    width: 100%;
    height: 100%;
}

.Home .Titulo {
    font-family: var(--rowTitleFont);
    color: var(--rowTitleColor)
}

.SpinnerHome {
    width: 100%;
    position: relative;
    text-align: center;
    float: left;
    margin-top: 20%;
}
