.selector-idade {
    display: block;
    background: #434343;
    color: white;
    padding: 10px;
    margin: 2px;
}

.App-icon {
	height: 25vmin;
	pointer-events: none;
	padding: 8vh;
}